@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FS Benjamin";
  src: url("./fonts/Fontsmith - FS Benjamin Bold.otf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "FS Benjamin";
  src: url("./fonts/Fontsmith - FS Benjamin Light.otf");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "FSMillbank";
  src: url("./fonts/Fontsmith - FSMillbank-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FSMillbank";
  src: url("./fonts/Fontsmith - FSMillbank-Light.otf");
  font-weight: light;
  font-style: normal;
}
