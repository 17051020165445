body,
html,
#root {
  height: 100%;
  width: 100%;
  font-family: "FS Benjamin", sans-serif;
}

@keyframes rotate {
  0% {
    stroke-dashoffset: 314;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes ping-sm {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping-md {
  75%,
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes ping-lg {
  75%,
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.hello {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.hello-md {
  animation: ping-md 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation-delay: 250ms;
}

.show-progress {
  /* calculate using: (2 * PI * R) */
  stroke-dasharray: 314;
  stroke-dashoffset: 0;
  animation-name: rotate;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.stop-progress {
  /* calculate using: (2 * PI * R) */
  stroke-dasharray: 314;
  stroke-dashoffset: 314;
}
