.playerPointer {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 1px solid rgba(128, 0, 128, 0.1); /* Purple border with 0.2 opacity */
  overflow: hidden;
  background-repeat: no-repeat;
  z-index: 1000;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2); /* Add drop shadow */
}

.playerPointer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.5) 10%,
    rgba(255, 255, 255, 0.3) 90%
  ); /* Updated gradient */
  border-radius: 50%;
  opacity: 0.5;
}

.stroke-white {
  stroke: rgba(255, 255, 255, 0.5);
}

.fill-white {
  fill: rgba(
    255,
    255,
    255,
    0.6
  ); /* Fill circle with a semi-transparent white */
}
